.pdf-viewer {
  padding: 20px;
  text-align: center; /* Center the content */
}

.controls {
  margin-bottom: 20px;
}

select {
  margin-bottom: 10px;
}

.download-button {
  display: block;
  margin: 0 auto; /* Center button */
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.pdf-viewer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-embed {
  width: 210mm; /* Width of A4 page */
  height: 297mm; /* Height of A4 page */
  border: 1px solid #ddd;
}

.default-page {
  text-align: center;
}
