/* Global table container styling */
.table-container {
    width: 100%;
  
    overflow-x: auto; /* Enables horizontal scrolling */
    margin-bottom: 120px; /* Adds space below the table */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile devices */
}

/* Global table styling */
table {
    width: 100%;
    padding: 100px;
    border-collapse: collapse; /* Collapses borders */
    border-spacing: 0; /* Removes spacing between cells */
    margin: 0; /* Removes default margin */
}

/* Styling for table headers */
table th {
    padding: 8px; /* Padding inside header cells */
    border: 1px solid #ddd; /* Border for header cells */
    background-color: #f2f2f2; /* Light grey background for headers */
    text-align: left; /* Left-align text in header cells */
}

/* Styling for table data cells */
table td {
  
    padding: 8px; /* Padding inside data cells */
    border: 1px solid #ddd; /* Border for data cells */
    text-align: left; /* Left-align text in data cells */
}

/* Optional: Styling for striped rows */
table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Optional: Hover effect on rows */
table tr:hover {
    background-color: #f1f1f1;
}

/* Scrollbar customization */
.table-container::-webkit-scrollbar {
    height: 8px; /* Height of the scrollbar */
}

.table-container::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}
