/* General form styles */
form {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

input, select, textarea {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.preview {
  max-width: 200px;
  max-height: 200px;
  display: block;
  margin-top: 10px;
}

/* Grid layout for qualifications and experience */
.qualification-grid, .experience-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;
}

.qualification-grid label, .experience-grid label {
  margin-bottom: 5px;
}

/* Button styles */
.form-button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.form-button:hover {
  background-color: #0056b3;
}

.form-button-remove {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #dc3545;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.form-button-remove:hover {
  background-color: #c82333;
}
