.serviceArea {
  background: #f1f1f1;
  padding: 50px 0 20px;
  position: relative;
  margin-top: -10px;
  .serviceItem {
    overflow: hidden;
    margin-bottom: 30px;
    background: #fff;
    .serviceIcon {
      background: #c0b596;
      font-size: 60px;
      height: 100px;
      max-width: 100px;
      width: 95px;
      text-align: center;
      float: left;
      @media (max-width: 1200px) {
        height: 90px;
        width: 90px;
      }
    }
    .serviceText {
      overflow: hidden;
      padding: 16px 25px;
      span {
        color: #ada282;
        font-size: 16px;
      }
      h3 {
        font-size: 30px;
        color: #333;
        @media (max-width: 1200px) {
          font-size: 22px;
        }
      }
    }
  }
  &.serviceAreaStyleTwo {
    background: url(../../images/services/1.jpg) no-repeat center center / cover;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(21, 26, 48, 0.66);
      z-index: -9;
    }
    .serviceItem {
      background: transparent;
      border: 1px solid rgba(192, 181, 150, 0.4);
      .serviceIcon {
        background: transparent;
        border-right: 1px solid rgba(192, 181, 150, 0.4);
        i {
          color: #c0b596;
        }
      }
      .serviceText {
        h3 {
          color: #fff;
        }
      }
    }
  }
}
.jconten {
  justify-content: space-around;
}
