img {
  display: block; /* Ensures the image behaves like a block element */
  max-width: 98%; /* Ensures the image doesn't overflow its container */
  height: auto; /* Maintains aspect ratio */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), /* Primary shadow */
              0 6px 20px rgba(0, 0, 0, 0.19); /* Additional shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  margin-left: auto; /* Center the image */
  margin-right: auto; /* Center the image */
}

img:hover {
  transform: scale(1.05); /* Slightly enlarges the image on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), /* Enhanced shadow on hover */
              0 12px 40px rgba(0, 0, 0, 0.23); /* Additional shadow on hover */
}
 .spanHeading{
  font-size: 24px;
  font-weight: bold;
 font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 10px 0;
  display: block;
  text-transform: uppercase;
  text-align: center;
  border: 2px solid #000; /* Adding a border */
  padding: 10px; /* Adding padding for better appearance */
  border-radius: 5px; /* Optional: Adding rounded corners */
 }
.divtable{
 
    width: 100%;
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents wrapping of inline elements */
    -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on touch devices */
     
    padding: 10px; /* Optional: Adds padding inside the scrollable container */
}

 
 /* Styling the table */
table {
  width: 98% !important;
 
  border-collapse: collapse; /* Ensures borders are shared between cells */
  margin: 10px 0; /* Adds margin around the table */
}

/* Styling table headers */
th {
  background-color: #f2f2f2;
  padding: 10px;
  border: 1px solid #000; /* Border for table headers */
}

/* Styling table cells */
td {
  padding: 10px;
  font-size: small;
  border: 1px solid #000; /* Border for table cells */
  text-align: left; /* Aligns text to the left */
}

/* Optional: Add alternating row colors for better readability */
tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:nth-child(even) {
  background-color: #fff;
}
