 
  /* Back button styles */
.back-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px 0;
}

.back-button img {
    width: 50px; /* Adjust size as needed */
    height: auto;
    margin-right: 8px; /* Space between icon and text */
}

.back-button span {
    font-size: 16px; /* Adjust font size if needed */
    color: #333; /* Color for the text */
}

  /* Container for folders */
.folder-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px;
    justify-content: center; /* Center align items horizontally */
}

/* Folder styles */
.folder {
    width: 120px;
    height: 100px;
    background-color: transparent; /* Light yellow color for the folder */
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    transition: transform 0.2s ease;
    text-align: center; /* Center text */
}

/* Hover effect */
.folder:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Folder icon representation */
.folder-icon {
    width: 80px;
    height: 60px;
    background-image: url('../../../../src/images/folder-icon.png');  
    background-size: cover;
    background-position: center;
}

/* Folder label (name) */
.folder-label {
    position: absolute;
    bottom: 5px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #333;
}

/* Image container styles */
.images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between images */
    justify-content: center; /* Center align images horizontally */
}

/* Image styles */
.images img {
    width: 200px; /* Fixed width */
    height: auto; /* Maintain aspect ratio */
    margin: 10px;
}
