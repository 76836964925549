/* Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal content */
.modal-content {
  position: relative;
  max-width: 88%; /* 98% of the screen width */
  max-height: Auto; /* 98% of the screen height */
  min-height: 200px;
  background: #fff; /* White background for modal content */
  padding: 50px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for a nice effect */
  border-radius: 5px; /* Optional rounded corners */
}

/* Close button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #333;
  border: none;
  background: transparent;
  cursor: pointer;
}

/* Image in modal */
.modal-image {
  width: 100%; /* 100% of the modal content width */
  height: auto; /* Maintain aspect ratio */
  max-height: 90vh; /* 90% of the viewport height to prevent overflow */
  display: block;
}
