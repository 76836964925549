/* Slideshow.css */

.slideshow-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9; /* Change as necessary */
}

.slideshow-container {
  text-align: center;
}

.slideshow-header {
  font-size: 2rem;
  margin-bottom: 20px;
}

.slideshow-back-button {
  margin: 10px 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slideshow-back-button:hover {
  background-color: #0056b3;
}

.slideshow-folder-nav {
  margin: 20px 0;
}

.slideshow-folder-button {
  margin: 5px;
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slideshow-folder-button:hover {
  background-color: #218838;
}

.slideshow {
  position: relative;
  width: 100%;
  max-width: 600px; /* Adjust max width as needed */
  overflow: hidden;
}

.slideshow-slide {
  cursor: pointer;
}

.slideshow-image {
  width: 100%;
  height: auto;
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
}

.slideshow-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slideshow-nav-button.prev {
  left: 10px;
}

.slideshow-nav-button.next {
  right: 10px;
}

.slideshow-placeholder {
  margin: 20px 0;
  font-size: 1.5rem;
}
.slideshow-modal {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center align children */
  justify-content: center; /* Center vertically */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background with transparency */
  z-index: 1000; /* Ensure modal is above other elements */
}

.slideshow-close-button {
  align-self: flex-end; /* Align close button to the top right */
  font-size: 24px; /* Increase size for visibility */
  color: white; /* Close button color */
  cursor: pointer; /* Pointer cursor on hover */
  margin: 10px; /* Space around the close button */
}

.slideshow-caption {
  color: white; /* Caption text color */
  font-size: 18px; /* Font size for the caption */
  margin-bottom: 10px; /* Space below the caption */
  text-align: center; /* Center align the caption text */
}

.slideshow-modal-content {
  max-width: 90%; /* Maximum width of the image */
  max-height: 80%; /* Maximum height of the image */
  border: 2px solid white; /* Optional border */
}